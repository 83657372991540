import { IoFastFoodOutline } from 'react-icons/io5';
import AppStorage from '@/utils/helpers';

export default function DiscoverOurMenu() {
  const menuCategories = [
    {
      name: 'Food',
      imgUrl: '/images/food.jpg',
      id: 1,
    },
    {
      name: 'Protein',
      imgUrl: '/images/Protein.jpg',
      id: 2,
    },
    {
      name: 'Pastries',
      imgUrl: '/images/Pastries.jpg',
      id: 3,
    },
    {
      name: 'Cakes',
      imgUrl: '/images/Cakes.jpg',
      id: 4,
    },
    {
      name: 'Shawarma',
      imgUrl: '/images/Shawarma.jpg',
      id: 5,
    },
    {
      name: 'Bread',
      imgUrl: '/images/Bread1.jpg',
      id: 6,
    },
  ];

  return (
    <section className="px-5 py-20 lg:py-40">
      <div className="w-full max-w-[1440px] mx-auto grid gap-10">
        <h2 className="text-crunchies-red grid gap-3 text-center justify-center">
          <div>Discover Our Menu</div>
          <div className="bg-crunchies-red w-1/5 h-2 mx-auto rounded-full"></div>
        </h2>

        {/* Menu content */}
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3 w-full max-w-5xl mx-auto px-5">
          {menuCategories.map((item: any, index: number) => (
            <a
              href="/menu"
              onClick={() =>
                AppStorage.save('activeCategory', item.id.toString())
              }
              key={index}
              // style={{ backgroundImage: `url(${item.imgUrl})` }}
              className={`aspect-video bg-crunchies-neutral text-crunchies-white bg-cover rounded-xl flex items-end relative overflow-hidden`}
            >
              <img src={item.imgUrl} alt={item.name} />
              <p className="font-bold text-3xl md:text-4xl absolute bottom-4 left-2">
                {item.name}
              </p>
            </a>
          ))}
        </div>

        <div>
          <a href="/menu">
            <button
              className="flex gap-2 items-center bg-crunchies-red text-crunchies-white mx-auto py-3 px-5 font-bold rounded-lg"
              onClick={() => AppStorage.save('activeCategory', '0')}
            >
              <IoFastFoodOutline />
              <div>See More</div>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}
