import axios, { InternalAxiosRequestConfig } from 'axios';
import store from 'store';
import AppStorage from './helpers';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = store.get('atk');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const successHandler = (response: any) => {
  const token = response.data.token;
  if (token) {
    store.set('atk', token);
  }
  return response;
};

const errorHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    // Clear the store
    store.remove('atk');
    AppStorage.remove('user');
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

// Implement this function based on your routing setup

export default axiosInstance;
