import axiosInstance from '@/utils/https';

export interface PaystackTransactionResponse {
  data: {
    authorization_url: string;
    access_code: string;
    reference: string;
    paid_amount: number;
  };
}

export interface StartPayment {
  order_id: string;
  channels?: Array<string>;
}

export const AddToCart = async (payload: any): Promise<any> => {
  try {
    const url = `api/v1/cart/add`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateCartItem = async (
  id: string,
  outletId: string,
  payload: any
) => {
  try {
    const url = `api/v1/cart/${outletId}/${id}`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const RemoveCartItem = async (id: string, outletId: string) => {
  try {
    const url = `api/v1/cart/remove/${outletId}/${id}`;
    const response = await axiosInstance.delete(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ReplaceCartComboItem = async (
  id: string,
  outletId: string,
  payload: any
) => {
  try {
    const url = `api/v1/cart/replace/${outletId}/${id}`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCartItems = async () => {
  try {
    const url = `api/v1/cart`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CheckOut = async (payload: any) => {
  try {
    const url = `api/v1/cart/process`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateCheckOut = async (payload: any) => {
  try {
    const url = `api/v1/cart/process/update`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const CheckOutPayment = async (
  payload: StartPayment
): Promise<PaystackTransactionResponse> => {
  try {
    const url = `api/v1/cart/process/pay`;
    const response = await axiosInstance.post(url, payload);

    return response.data as PaystackTransactionResponse;
  } catch (error) {
    throw error;
  }
};

export const GetDeliveryFee = async (lat: number, lng: number) => {
  try {
    const url = `api/v1/cart/delivery-fee?lat=${lat}&long=${lng}`;
    const res = await axiosInstance.get(url);

    return res.data;
  } catch (error) {
    throw error;
  }
};
