'use client';

import { addToOrderData, clearCart } from '@/redux/slices/cart.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AppStorage from '@/utils/helpers';
import AuthModal from '@/components/AuthModal';
import { CheckOut, UpdateCheckOut } from '@/services/cart.api';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import NoOutletModal from '@/components/NoOutletModal';
import { getErrorMessge } from '@/utils/helpers';
import { toast } from 'react-toastify';
import { usePathname, useRouter } from 'next/navigation';
import { useAppSelector } from '@/redux/hooks';

type CheckOutProps = {
  cartItem: any;
  price: number;
  closeModal: () => void;
  setCartTotalPrice: (price: number) => void;
};

const CheckoutTab = ({
  cartItem,
  price,
  closeModal,
  setCartTotalPrice,
}: CheckOutProps) => {
  const outlet = JSON.parse(AppStorage.get('outlet') as string);
  const { cart: myCart } = useSelector((state: any) => state.cart);
  const { location } = useSelector((state: any) => state.userLocation);
  const [showNoOutletModal, setShowNoOutletModal] = useState(false);
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateCouponLoading, setValidateCouponLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  // const [coupon, setCoupon] = useState('');
  const [couponValue, setCouponValue] = useState({
    isValid: false,
    value: 0,
  });
  const [openLogin, setOpenLogin] = useState({
    open: false,
    state: '',
  });
  const dispatch = useDispatch();
  const router = useRouter();
  const { coupon } = useSelector((state: any) => state.cart);
  const pathname = usePathname();
  const isSelfService = pathname.split('/').length === 3;
  const orderid = AppStorage.get('orderId') as string;
  // const location = useSelector((state: any) => state.userLocation);

  const checkOut = async () => {
    const orderType = AppStorage.get('orderType');

    const menuItems = myCart?.map((cartItem: any) => ({
      menuId: cartItem.id,
      quantity: cartItem.quantity,
    }));
    try {
      setLoading(true);
      if (isAuthenticated) {
        const payload = {
          latitude:
            outlet.location.coordinates[1].toString() ||
            location.lat.toString(),
          longitude:
            outlet.location.coordinates[0].toString() ||
            location.lng.toString(),
          orderType: orderType,
          couponCode: coupon,
          menuItems,
          orderSource: isSelfService ? 'SLS' : 'WEB',
          userid: user?._id,
          ...(!isSelfService && { order_id: orderid }),
          ...(isSelfService && { channels: ['bank_transfer'] }),
        };
        const res = isSelfService
          ? await CheckOut(payload)
          : await UpdateCheckOut(payload);

        dispatch(addToOrderData(res));
        AppStorage.save('orderId', res.serializedOrder._id);
        isSelfService &&
          AppStorage.save('paystackUrl', res.data.authorization_url);
      }
      if (!coupon) {
        AppStorage.remove('coupon_type');
        AppStorage.remove('coupon_rate');
        AppStorage.remove('coupon_code');
        AppStorage.remove('couponPrice');
      }

      isSelfService ? router.push('/checkout-sls') : router.push('/checkout');
    } catch (error) {
      console.error(error);
      const errorMsg = getErrorMessge(error);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <section className="bg-white px-4 py-5 flex flex-col">
      <section>
        <ul className="flex flex-col gap-3 text-base font-semibold py-6">
          <li className="flex justify-between">
            <p>Total</p>
            <p>₦{price.toLocaleString()}</p>
          </li>
          <li>
            <button
              onClick={checkOut}
              className="flex cursor-pointer items-center rounded-full text-center bg-primary-500 py-4 px-6 font-medium w-full text-white "
            >
              <span className="grow">
                {loading ? 'Loading...' : 'Checkout'}
              </span>
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </li>
        </ul>
      </section>
      <NoOutletModal
        open={showNoOutletModal}
        closeModal={() => {
          setShowNoOutletModal(false);
        }}
      />
    </section>
  );
};

export default CheckoutTab;
